$color: #fff;
$orange: #FFB752;
$blue: #438EB9;
$green: #629B58;


@font-face {
    font-family: 'Mandala';
    src:url('/fonts/Mandala.ttf.woff') format('woff'),
        url('/fonts/Mandala.ttf.svg#Mandala') format('svg'),
        url('/fonts/Mandala.ttf.eot'),
        url('/fonts/Mandala.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

.uppercase{
	text-transform:uppercase;
}
.cursor-pointer{
	cursor:pointer;
}
.noresize {
	resize: none;
}

.unselectable{
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.loader-container{
	z-index:9999999;
	/*background-color:rgba(0,0,0,.8);*/
	background-color:rgba(255,255,255,1);
	position:fixed;
	width:100%;
	height:100%;
}
.cssload-loader {
	position: absolute;
	top: calc(45% - 50px);
	left: calc(50% - 50px);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	perspective: 780px;
	background-color:transparent;
}

.cssload-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;	
}

.cssload-inner.cssload-one {
	left: 0%;
	top: 0%;
	animation: cssload-rotate-one 1.15s linear infinite;
	-o-animation: cssload-rotate-one 1.15s linear infinite;
	-ms-animation: cssload-rotate-one 1.15s linear infinite;
	-webkit-animation: cssload-rotate-one 1.15s linear infinite;
	-moz-animation: cssload-rotate-one 1.15s linear infinite;
	border-bottom: 3px solid $orange;
}

.cssload-inner.cssload-two {
	right: 0%;
	top: 0%;
	animation: cssload-rotate-two 1.15s linear infinite;
	-o-animation: cssload-rotate-two 1.15s linear infinite;
	-ms-animation: cssload-rotate-two 1.15s linear infinite;
	-webkit-animation: cssload-rotate-two 1.15s linear infinite;
	-moz-animation: cssload-rotate-two 1.15s linear infinite;
	border-right: 3px solid $blue;
}

.cssload-inner.cssload-three {
	right: 0%;
	bottom: 0%;
	animation: cssload-rotate-three 1.15s linear infinite;
	-o-animation: cssload-rotate-three 1.15s linear infinite;
	-ms-animation: cssload-rotate-three 1.15s linear infinite;
	-webkit-animation: cssload-rotate-three 1.15s linear infinite;
	-moz-animation: cssload-rotate-three 1.15s linear infinite;
	border-top: 3px solid $green;
}







@keyframes cssload-rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-one {
	0% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-one {
	0% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-one {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-one {
	0% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-two {
	0% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-two {
	0% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-two {
	0% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-two {
	0% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-three {
	0% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-three {
	0% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-three {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-three {
	0% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}



.pointer{
	cursor:pointer!important;
}


.backto-homepage-button{
	text-align:center;
	width:100%!important;
}
.backto-homepage-button a{
	text-align:center;
	display:block;
	color:#CCFF77;
}

.cs-options > ul{
	max-height:155px;
	overflow-y:scroll;
}

.nopadding-bottom{
	padding-bottom:0px;
}
.nopadding-right{
	padding-right:0px;
}
.nopadding-left{
	padding-left:0px;
}
.nopadding-top{
	padding-top:0px;
}

.red{
	color:red;
}
.left{
	text-align:left!important;
}